<template>
  <div>
    <h3>{{ title }}</h3>
    <v-progress-linear color="#56ca00" rounded height="25" v-model="valueReturned">
      <template v-slot:default="{ value }">
        <strong>{{ value }}%</strong>
      </template>
    </v-progress-linear>
  </div>
</template>

<script>
export default {
  name: "ProgressLinearPercentComponent",

  props: {
    valueObtained: {
      require: true,
      default: 0,
    },
    valueMax: {
      require: true,
      default: 0,
    },
    isPercent: {
      require: false,
      default: true,
    },
    title: {
      require: false,
      default: "Média geral",
    },
  },

  methods: {},

  computed: {
    valueReturned() {
      if (!this.isPercent) return this.valueObtained;

      const valuePercent = (this.valueObtained * 100) / this.valueMax;

      return valuePercent;
    },
  },
};
</script>

<style></style>
