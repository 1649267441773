import { render, staticRenderFns } from "./PainelDeBordo.vue?vue&type=template&id=71f505be&scoped=true"
import script from "./PainelDeBordo.vue?vue&type=script&lang=js"
export * from "./PainelDeBordo.vue?vue&type=script&lang=js"
import style0 from "./PainelDeBordo.vue?vue&type=style&index=0&id=71f505be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f505be",
  null
  
)

export default component.exports