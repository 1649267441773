<template>
  <v-card
    class="pa-2 d-flex flex-column"
    elevation="2"
    style="background-color: white !important"
  >
    <div class="ma-2">
      <h3 class="text--grey lighten-3 font-weight-regular">{{ nome }}</h3>
    </div>

    <VueApexCharts
      type="bar"
      height="350"
      :options="chartOptions"
      :series="seriesData"
    ></VueApexCharts>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "CardCompetenciaComponent",

  props: {
    nome: {
      require: true,
      default: "",
    },
    series: {
      require: true,
      default: [],
    },
  },

  components: {
    VueApexCharts,
  },

  data() {
    return {
      chartOptions: {
        chart: {
          width: "100%",
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        colors: ["#162C40"],
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        grid: {
          show: false,
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    };
  },

  computed: {
    seriesData() {
      let data = [
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ];

      if (!this.series) {
        return data;
      } else if (
        this.series[0].data !== undefined &&
        this.series[0].data.length > 1
      ) {
        return this.series;
      }

      data[0].data = this.series;

      return data;
    },
  },
};
</script>

<style></style>
