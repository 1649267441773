import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-4",staticStyle:{"background-color":"#162c40 !important"},attrs:{"tag":"section"}},[_c(VRow,[_c(VCol,{attrs:{"sm":"12","md":"4","cols":"4"}},_vm._l((_vm.items),function(item){return _c(VCard,{key:item.id,staticClass:"cursor-pointer d-flex flex-row align-center white pa-4 ma-4",staticStyle:{"background-color":"white !important"},on:{"click":function($event){_vm.itemSelected = item}}},[_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c(VImg,{staticClass:"rounded-img",attrs:{"height":"90","width":"90","src":item.foto}}),_c('h4',{staticClass:"mt-2"},[_vm._v(_vm._s(item.nome))]),_c('h6',[_vm._v(_vm._s(item.cargo))])],1)]),_c(VCol,{attrs:{"cols":"2"}},[_c('div',{staticClass:"d-flex align-start justify-center"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-cursor-pointer")])]}}],null,true)},[_c('span',[_vm._v("Clique para ver as estatísticas deste colaborador")])])],1)])],1)],1)}),1),_c(VCol,{attrs:{"sm":"12","md":"8","cols":"8"}},[_c('div',{staticClass:"d-flex align-center justify-center height-full"},[_c(VCard,{staticClass:"d-flex flex-column align-center justify-center white pa-4 ma-4",staticStyle:{"background-color":"white !important"},attrs:{"width":"100%","height":"100%"}},[_c('div',[(_vm.itemSelected.nome)?_c('h3',[_vm._v(" Estatísticas de "+_vm._s(_vm.itemSelected.nome)+" ")]):_c('h3',[_vm._v("Clique em um colaborador para ver as estatisticas")])]),_c('div',[_c('VueApexCharts',{attrs:{"type":"radialBar","height":"450","width":"370","options":_vm.chartOptions,"series":_vm.series}})],1)])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }