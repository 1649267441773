<template>
  <section class="px-3">
    <ProgressLinearPercentComponent
      class="my-4"
      :valueObtained="dataPainelDeBordo.media.valorObtido"
      :valueMax="dataPainelDeBordo.media.valorMaximo"
    />

    <v-spacer></v-spacer>

    <div class="d-flex flex-column mt-8 mb-2">
      <h3>Ranking</h3>
      <v-divider></v-divider>
    </div>
    <RankingColaboradoresComponent :items="dataPainelDeBordo.ranking" />

    <v-spacer></v-spacer>

    <div class="d-flex flex-column mb-2 mt-8">
      <h3>Gráficos</h3>
      <v-divider></v-divider>

      <v-row class="my-4">
        <v-col sm="12" md="6" lg="6" xl="6" cols="12">
          <VueApexCharts
            type="area"
            height="350"
            width="100%"
            :options="grafico1ChartOptions"
            :series="dataPainelDeBordo.avaliacaoGeral"
          ></VueApexCharts>
        </v-col>
        <v-col sm="12" md="6" lg="6" xl="6" cols="12">
          <VueApexCharts
            type="radar"
            height="350"
            width="100%"
            :options="grafico2ChartOptions"
            :series="dataPainelDeBordo.competenciasGeral"
          ></VueApexCharts>
        </v-col>
      </v-row>
      <v-expansion-panels v-model="expansion" multiple :value="[0]">
        <v-expansion-panel>
          <v-expansion-panel-header>Competências</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col
                v-for="(competencia, i) in dataCompetencias"
                :key="'com-' + i"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                cols="12"
              >
                <div class="d-flex align-center justify-center">
                  <CardCompetenciaComponent
                    :nome="competencia.nome"
                    :series="competencia.series"
                    class="w-100 ma-2"
                  />
                </div>
              </v-col>
              <v-col cols="12">
                <div class="d-flex align-center justify-center">
                  <v-btn
                    :loading="loading"
                    dark
                    @click="buscarCompetencias()"
                    color="#162C40"
                    class="pa-2"
                    >Buscar mais competências
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </section>
</template>

<script>
import ProgressLinearPercentComponent from "./components/ProgressLinearPercentComponent.vue";
import RankingColaboradoresComponent from "./components/RankingColaboradoresComponent.vue";
import VueApexCharts from "vue-apexcharts";
import CardCompetenciaComponent from "./components/CardCompetenciaComponent.vue";

export default {
  name: "PainelDeBordo",
  components: {
    ProgressLinearPercentComponent,
    RankingColaboradoresComponent,
    VueApexCharts,
    CardCompetenciaComponent,
  },

  created() {
    this.$store.dispatch("avd/buscarDadosPainelDeBordo");
  },

  data() {
    return {
      grafico1ChartOptions: {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "Resultado geral por tipo de avaliação",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "month",
          categories: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ],
        },
        tooltip: {
          x: {
            format: "month",
          },
        },
      },
      grafico2ChartOptions: {
        chart: {
          height: 350,
          type: "radar",
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "Resultado geral das competências",
        },
        xaxis: {
          type: "month",
          categories: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ],
        },
      },
      loading: false,
      expansion: [0],
    };
  },

  computed: {
    dataPainelDeBordo: {
      get() {
        return this.$store.getters[
          "avd/getDataPainelDeBordo"
        ];
      },
    },
    dataCompetencias: {
      // Este data deverá trazer alem de competencias as informações do paginate
      get() {
        const item =
          this.$store.getters["avd/getDataCompetencias"];

        console.log(item);

        return item;
      },
    },
  },

  methods: {
    buscarCompetencias() {
      // Está função irá pegar o paginate atual e buscará por mais páginas!
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>
