<template>
  <v-card
    tag="section"
    class="pa-4"
    style="background-color: #162c40 !important"
  >
    <v-row>
      <v-col sm="12" md="4" cols="4">
        <v-card
          v-for="item in items"
          :key="item.id"
          style="background-color: white !important"
          class="cursor-pointer d-flex flex-row align-center white pa-4 ma-4"
          @click="itemSelected = item"
        >
          <v-row>
            <v-col cols="10">
              <div class="d-flex flex-column align-center justify-center">
                <v-img
                  class="rounded-img"
                  height="90"
                  width="90"
                  :src="item.foto"
                ></v-img>
                <h4 class="mt-2">{{ item.nome }}</h4>
                <h6>{{ item.cargo }}</h6>
              </div>
            </v-col>
            <v-col cols="2">
              <div class="d-flex align-start justify-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small
                      >mdi-cursor-pointer</v-icon
                    >
                  </template>
                  <span>Clique para ver as estatísticas deste colaborador</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="12" md="8" cols="8">
        <div class="d-flex align-center justify-center height-full">
          <v-card
            width="100%"
            height="100%"
            style="background-color: white !important"
            class="d-flex flex-column align-center justify-center white pa-4 ma-4"
          >
            <div>
              <h3 v-if="itemSelected.nome">
                Estatísticas de {{ itemSelected.nome }}
              </h3>
              <h3 v-else>Clique em um colaborador para ver as estatisticas</h3>
            </div>
            <div>
              <VueApexCharts
                type="radialBar"
                height="450"
                width="370"
                :options="chartOptions"
                :series="series"
              ></VueApexCharts>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "RankingColaboradoresComponent",

  props: {
    items: {
      require: true,
      default: {},
    },
  },

  components: {
    VueApexCharts,
  },

  data() {
    return {
      itemSelected: {
        id: 0,
        nome: "",
        foto: "https://cdn.pixabay.com/photo/2018/11/13/22/01/instagram-3814082_960_720.png",
        cargo: "",
        series: [0, 0, 0, 0],
        tipos: ["AA", "AP", "AL", "AG", "AC"],
      },
    };
  },

  computed: {
    series() {
      if (!this.itemSelected) return [0, 0, 0, 0, 0];

      return this.itemSelected.series;
    },
    chartOptions() {
      const chartOptions = {
        chart: {
          height: 350,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "22px",
              },
              value: {
                fontSize: "16px",
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return 0;
                },
              },
            },
          },
        },
        labels: [],
      };

      if (
        this.itemSelected &&
        this.itemSelected.tipos !== undefined &&
        this.itemSelected.tipos
      ) {
        for (let item of this.itemSelected.tipos) {
          const tipo = this.tiposAvaliacao[item];
          chartOptions.labels.push(tipo.nome);
        }
      }

      return chartOptions;
    },
    tiposAvaliacao: {
      get() {
        return this.$store.getters["avd/getTiposAvaliacao"];
      },
    },
  },
};
</script>

<style scoped>
.rounded-img {
  border-radius: 50% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  -webkit-box-shadow: 0px 0px 19px -3px #ffffff;
  box-shadow: 0px 0px 19px -3px #ffffff;
}

.height-full {
  height: 100% !important;
}
</style>
